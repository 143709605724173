import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'
import { feathersConnectionString } from "../../../app.config";

const apiClient = feathers()
const socket = io(feathersConnectionString, { transports: ['websocket']})

const delayApi = false
const delay = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout || 2000))

import router from "../../router/index";

const apiErrorHandler = (context) => {
    const { error } = context

    console.log(error);

    if (error && error.code === 401) {
        let eventName

        if (error.message.toLowerCase().includes('jwt expired')) eventName = 'session-expired'
        else if (error.message.toLowerCase().includes('not authenticated')) eventName = 'not-authenticated'
        else if (error.message.toLowerCase().includes('no accesstoken found')) eventName = 'not-authenticated'
        else if (error.message.toLowerCase().includes('disabled') && error.message.toLowerCase().includes("instance")) {
            eventName = 'not-authenticated';
            apiClient.emit("authFailure", error);
            if (router.currentRoute.meta?.requiresAuth) {
                window.location.reload(true);
            }
        }
        else console.log('Auth error:', error)

        if (eventName) apiClient.emit('authentication', { event: eventName })
    }

    return context
}

apiClient
    .configure(socketio(socket, {
        timeout: 15000
    }))
    .configure(auth({ storage: window.localStorage }))
    .hooks({
        before: {
            all: [
                async (context) => {
                    if (delayApi) await delay(2000)

                    return context
                },
                iff(
                    (context) => ['create', 'update', 'patch'].includes(context.method),
                    discard('__id', '__isTemp')
                )
            ]
        },
        error: {
            all: [apiErrorHandler]
        }
    })

export default apiClient

// Setting up feathers-vuex
const {
    makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex
} = feathersVuex(
    apiClient,
    {
        serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
        idField: 'id', // Must match the id field in your database table/collection
        whitelist: ['$regex', '$options', '$all'],
        paramsForServer: ['$eager', '$ilike', '$joinRelation', '$joinEager']
    }
)

const authManagement = apiClient.service('authManagement')

export {
    apiClient, authManagement, makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex
}
